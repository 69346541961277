/* eslint-disable unused-imports/no-unused-vars */
import React from 'react';

function FeatureSectionContent({
  sectionData,
  data,
}: {
  sectionData: any;
  data: any;
}) {
  const staticData = [
    {
      icon: 'ri-message-3-line',
      title: 'Broadcast Chat Generator',
      description: 'Kirim undangan dengan mudah untuk tamu kamu.',
    },
    {
      icon: 'ri-file-list-3-line',
      title: 'Tamu Undangan Tanpa Batas',
      description: 'Kamu bisa undang tamu tanpa batas.',
    },
    {
      icon: 'ri-music-2-line',
      title: 'Backsound',
      description: 'Tambahkan backsound untuk pengalaman yang lebih menarik.',
    },
    {
      icon: 'ri-survey-line',
      title: 'RSVP Tamu',
      description: 'Konfirmasi kehadiran tamu dengan fitur RSVP.',
    },
    {
      icon: 'ri-wallet-3-line',
      title: 'Amplop Digital',
      description: 'Tersedia amplop digital.',
    },
    {
      icon: 'ri-palette-line',
      title: 'Custom Tema',
      description: 'Kamu bisa Custom desain undangan kamu.',
    },
    {
      icon: 'ri-image-line',
      title: 'Gallery yang Keren',
      description: 'Tampilkan momen spesial dalam galeri yang menarik.',
    },
    {
      icon: 'ri-bard-line',
      title: 'Dan 20+ Fitur Keren Lainnya',
      description:
        'Jelajahi lebih dari 20 fitur keren lainnya untuk mempercantik acara Kamu.',
    },
  ];

  return (
    <div className="columns-2 gap-4 space-y-4 px-4">
      {staticData.map((item, i) => (
        <div
          key={i}
          className="grid h-auto max-w-full break-inside-avoid gap-1 rounded-xl bg-[#f2f2f2] px-4 py-3 sm:w-full"
        >
          <i className={`${item.icon} text-[44px] text-[#B28F60]`}></i>
          <div className="relative flex shrink-0 grow-0 flex-col items-start justify-center gap-1 self-stretch">
            <p className="text-md w-full shrink-0 grow-0 self-stretch whitespace-normal text-left font-bold text-[#38465b]">
              {item.title}
            </p>
            <p className="w-full shrink-0 grow-0 self-stretch whitespace-normal text-left text-sm text-[#7c7d8a]">
              {item.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default FeatureSectionContent;
